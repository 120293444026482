<template>
  <div
    class="slide-in-container"
    :class="{'active' : show === true}"
  >
    <div class="slide-in-bg"/>
    <div class="slide-in-content">
      <div class="flex justify-between">
        <div>
          <h1 class="text-xl mb-5">
            Select contact details
          </h1>
        </div>
        <div>
          <div
            class="close"
            @click="cancel"
          />
        </div>
      </div>

      <div class="flex flex-wrap mt-2 justify-between">
        <div
          class="w-full md:w-2/3 px-2"
        >
          <div class="w-full input-group block">
            <div v-if="!isNewContact">
              <label>Search for a contact</label>
              <autocomplete
                :search="searchContact"
                :get-result-value="getContactResult"
                :debounce-time="500"
                @submit="searchContactClick"
              />
            </div>


            <div v-if="isNewContact">
              <div class="mb-4">
                <strong class="text-black">Add new contact {{ isNewContact.toString() }}</strong>
              </div>
              <form
                method="post"
                autocomplete="off"
                @submit.prevent="submit"
              >
                <div class="input-group">
                  <label for="name"> First and last name </label>
                  <input
                    id="name"
                    v-model="name"
                    v-validate="{ required: true }"
                    name="name"
                    type="text"
                  >
                  <span
                    v-if="errors.has('name')"
                    class="error"
                  >{{ errors.first('name') }}</span>
                </div>
                <div class="input-group">
                  <label for="email"> Email address </label>
                  <input
                    id="email"
                    v-model.lazy="email"
                    v-validate="{ required: true, email: true }"
                    name="email"
                    type="text"
                    @blur="checkEmail"
                  >
                  <span
                    v-if="errors.has('email')"
                    class="error"
                  >{{ errors.first('email') }}</span>
                </div>
                <div class="input-group">
                  <label for="phone"> Phone number </label>
                  <input
                    id="phone"
                    v-model="phone"
                    type="text"
                  >
                </div>
                <div class="input-group">
                  <label for="address"> Address </label>
                  <input
                    id="address"
                    v-model="address"
                    type="text"
                  >
                </div>
                <div class="input-group">
                  <label for="postal_code"> Postal code </label>
                  <input
                    id="postal_code"
                    v-model="postal_code"
                    type="text"
                  >
                </div>

                <div class="input-group">
                  <label for="city"> City </label>
                  <input
                    id="city"
                    v-model="city"
                    type="text"
                  >
                </div>
                <div class="input-group">
                  <label for="country"> Country </label>
                  <div class="select-container w-full">
                    <select v-model="country">
                      <option :value="null">
                        Select a country
                      </option>
                      <option
                        v-for="(item, index) in countriesList"
                        :key="index"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="input-group">
                  <label for="company"> Company Name </label>
                  <input
                    id="company"
                    v-model="company"
                    type="text"
                  >
                </div>
                <div class="input-group">
                  <label for="vat"> VAT number </label>
                  <input
                    id="vat"
                    v-model="vat"
                    type="text"
                  >
                </div>
                <div>
                  <v-checkbox
                    id="isSameAsBilling"
                    v-model="isSameAsBilling"
                    :checked="isSameAsBilling"
                    label="Same as billing address"
                  />
                </div>

                <hr class="mt-3 mb-5">

                <div
                  v-if="!isSameAsBilling"
                  class="mb-5"
                >
                  <div class="input-group">
                    <label for="billing_name">Billing First and last name </label>
                    <input
                      id="billing_name"
                      v-model="billing_name"
                      name="billing_name"
                      type="text"
                    >
                  </div>
                  <div class="input-group">
                    <label for="billing_phone">Billing Phone number </label>
                    <input
                      id="billing_phone"
                      v-model="billing_phone"
                      type="text"
                    >
                  </div>
                  <div class="input-group">
                    <label for="billing_address">Billing Address </label>
                    <input
                      id="billing_address"
                      v-model="billing_address"
                      type="text"
                    >
                  </div>
                  <div class="input-group">
                    <label for="billing_city">Billing City </label>
                    <input
                      id="billing_city"
                      v-model="billing_city"
                      type="text"
                    >
                  </div>
                  <div class="input-group">
                    <label for="billing_postal_code">Billing Postal code </label>
                    <input
                      id="billing_postal_code"
                      v-model="billing_postal_code"
                      type="text"
                    >
                  </div>
                  <div class="input-group">
                    <label for="billing_country">Billing Country </label>
                    <div class="select-container w-full">
                      <select v-model="billing_country">
                        <option :value="null">
                          Select a country
                        </option>
                        <option
                          v-for="(item, index) in countriesList"
                          :key="index"
                          :value="item.name"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="input-group">
                  <label for="notes"> Notes (only visible to you) </label>
                  <textarea v-model="notes"/>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/3 px-2">
          <a
            class="md:mt-10 green underline cursor-pointer mt-2 mb-3 md:mb-0 md:ml-3 block"
            @click="toggleContact"
          >
            <span v-if="!isNewContact">Add a new contact</span>
            <span v-if="isNewContact">Search a contact by name</span>
          </a>
        </div>
      </div>
    </div>
    <div
      class="actions"
    >
      <div
        v-if="!loading"
        class="flex"
      >
        <div>
          <v-button
            class="btn--secondary"
            @click="cancel"
          >
            Cancel
          </v-button>
        </div>
        <div class="pl-4">
          <v-button
            class="green-btn"
            @click="save"
          >
            Save
          </v-button>
        </div>
      </div>
      <div
        v-else
        class="text-center"
      >
        <img
          src="@/assets/img/icons/loader.svg"
          width="40"
        >
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/auth'
import {logException} from '@/helpers'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      user: auth.user(),
      isNewContact: false,
      loading: false,
      emailError: false,
      hasError: false,
      contact_id: null,
      selectedContact: null,
      id: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      postal_code: '',
      city: '',
      country: null,
      company: '',
      vat: '',
      notes: '',
      isSameAsBilling: true,
      countriesList: [],
      billing_name: '',
      billing_phone: '',
      billing_address: '',
      billing_postal_code: '',
      billing_city: '',
      billing_country: '',
    }
  },
  async mounted() {
    this.getCountriesList()
    this.getContacts()
  },
  methods: {
    async getCountriesList() {
      try {
        const { data } = await this.$api.get('country').all()
        this.countriesList = data.countries
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    async checkEmail() {
      this.emailError = false

      try {
        const { data } = await this.$api.get('contacts').checkEmail({
          contactEmail: this.email,
          contactId: this.id
        })

        if(data.contact){
          this.emailError = true
          this.errors.add({
            field: 'email',
            msg: 'This email address is already in use.'
          })
        }
      } catch(e){
        logException(e)
      }
    },
    cancel() {
      this.$emit('close')
    },
    async getContacts() {
      try {
        const {data} = await this.$api.get('contacts').search('')
        this.contactList = data.results
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    save() {
      if (this.emailError) {
        return false
      }
      this.$validator.validate().then(result => {
        if (result) {
          this.doSave()
        } else {
          this.$toasted.global.general_error({
            message: 'The fields for contact name or email are invalid.'
          })
        }
      })
    },
    getContactResult(result) {
      return result.first_name + ' ' + result.last_name
    },
    searchContactClick(result) {
      this.selectedContact = result
    },
    toggleContact() {
      this.selectedContact = null
      this.isNewContact = !this.isNewContact
    },
    async searchContact(input) {
      if (input.length < 3) {
        return []
      }

      this.selectedContact = null
      try {
        const {data} = await this.$api.get('contacts').search(input)
        const {results} = data
        return results
      } catch (e) {
        logException(e)
      }
    },
    async doSave() {

      this.loading = true
      const postData = {
        companyId: this.user.me.company_id,
        contactId: null,
        contactName: this.name,
        contactEmail: this.email,
        contactPhone: this.phone,
        contactAddress: this.address,
        contactPostalCode: this.postal_code,
        contactCity: this.city,
        contactCountry: this.country,
        contactCompany: this.company,
        contactVat: this.vat,
        contactNotes: this.notes,
        isSameAsBilling: this.isSameAsBilling,
        billing_name: this.billing_name,
        billing_phone: this.billing_phone,
        billing_address: this.billing_address,
        billing_postal_code: this.billing_postal_code,
        billing_city: this.billing_city,
        billing_country: this.billing_country,
      }

      if (this.isNewContact === true) {
        try {
          const {data} = await this.$api.get('contacts').save(postData)
          this.$emit('selectedContact', data.contact.id)
          setTimeout(() => this.loading = false, 300)
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      } else {
        this.$emit('update:contact', this.selectedContact);
        this.$emit('selectedContact', this.selectedContact.id)
        this.$emit('close')
        setTimeout(() => this.loading = false, 300)
      }
    }
  }
}
</script>
